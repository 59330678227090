<template lang="html">
  <div>
    <!-- FAQ Section -->
    <div>
      <h2 class="text-center mb-5">Frequently Asked Questions</h2>
      <div class="faq-container mx-auto w-80">
        <div class="position-relative">
          <h3>1. What is Paramount Students?</h3>
          <p>
            Paramount Students is an online platform that connects high schools,
            universities, and students globally. It simplifies the process of
            applying to study abroad programs by providing tools for easy
            applications, virtual campus tours, and more.
          </p>

          <h3>2. Who can use Paramount Students?</h3>
          <p>
            The platform is designed for high school students looking to study
            abroad, high school administrators and staff, and university program
            administrators.
          </p>

          <h3>3. How does student privacy work on Paramount Students?</h3>
          <p>
            We implement industry-standard encryption and access controls to
            protect student data privacy. Our platform complies with regulations
            like GDPR and COPPA regarding personal data.
          </p>

          <h3>4. How does the student verification process work?</h3>
          <p>
            Students sign up on the Paramount Students app and create a profile,
            indicating their high school. The profile is sent to your school's
            admin portal for verification. An admin reviews and verifies the
            accuracy of the information. Once verified, the student profile is
            activated.
          </p>

          <h3>5. What information does our school need to verify?</h3>
          <p>
            You'll need to check the information provided in the student's
            profile, such as their enrollment status, contact info, academic
            stats, and any other information relevant to university applications.
          </p>

          <h3>6. Is the verification process automatic or manual?</h3>
          <p>
            Currently, the process is manual. A designated admin from your school
            reviews and approves each student signup before accounts are
            activated.
          </p>

          <h3>7. How does the high school incentive model work?</h3>
          <p>
            Your school receives $5 for every student that completes an
            application to any university program on Paramount Students. If the
            student successfully enrolls in a university abroad using our
            platform, your school receives an additional $200 per student.
          </p>

          <h3>8. When are incentive payments made?</h3>
          <p>
            Incentive fees are paid once per semester, after the university
            enrollment period concludes, within a 90-day payment window. Payment
            timing is based on reporting student application and enrollment
            numbers in your dashboard portal for the concluded semester.
          </p>

          <h3>9. How are payments made?</h3>
          <p>
            Payments are made via wire transfer to the account details provided
            by your school.
          </p>

          <h3>10. What does it cost our school to use Paramount Students?</h3>
          <p>
            Nothing - registration and use of Paramount Students is currently
            free for all high schools and does not require a contract. Incentive
            payouts represent pure incremental revenue for your school.
          </p>

          <h3>11. How can we track our students' progress through the application process?</h3>
          <p>
            Your dashboard provides real-time updates on student applications,
            including which universities they've applied to and the status of each
            application.
          </p>

          <h3>12. Can we communicate with our students through the platform?</h3>
          <p>
            Yes, the platform includes features for direct communication with
            students who have contacted your school through Paramount Students.
          </p>

          <h3>13. How can we promote Paramount Students to our students?</h3>
          <p>
            We provide marketing materials that you can use to promote the
            platform. Additionally, you can organize information sessions or
            include details about Paramount Students in your college counseling
            resources.
          </p>

          <h3>14. What kind of support is available if we have questions or issues?</h3>
          <p>
            We offer comprehensive technical support, including setup assistance,
            troubleshooting, and ongoing support. You can reach our support team
            through your dashboard or via email.
          </p>

          <h3>15. How often is the platform updated?</h3>
          <p>
            We regularly update the platform to improve functionality, add new
            features, and ensure security. Major updates are typically rolled out
            quarterly, with critical updates implemented as needed.
          </p>

          <h3>16. Can we provide feedback or request new features?</h3>
          <p>
            Absolutely! We value your feedback and continuously strive to improve
            our platform. You can submit feature requests and feedback through your
            dashboard or by contacting your account manager.
          </p>
        </div>
      </div>
    </div>

   <!-- Help Videos Section -->
<div class="mt-5">
  <h2 class="text-center mb-2">
    <span lang="en" class="notranslate" translate="no">Paramount Students</span> help guide in videos
  </h2>
  <div class="help-videos-container position-relative">
    <div class="help-videos d-flex justify-content-center flex-wrap">
      <div class="d-flex align-items-center flex-column video-card">
        <div class="videoContainer help-video">
          <iframe width="250" height="130" src="https://www.youtube.com/embed/EH6LJZG1xqM" frameborder="0" allowfullscreen></iframe>
        </div>
        <h3 class="mt-1 text-center">Welcome to Paramount Students - High Schooler Sign Up Process</h3>
      </div>

      <div class="d-flex align-items-center flex-column video-card">
        <div class="videoContainer help-video">
          <iframe width="250" height="130" src="https://www.youtube.com/embed/S06aUE_OLpY" frameborder="0" allowfullscreen></iframe>
        </div>
        <h3 class="mt-1 text-center">Discover Academic Programs with Paramount Students</h3>
      </div>

      <div class="d-flex align-items-center flex-column video-card">
        <div class="videoContainer help-video">
          <iframe width="250" height="130" src="https://www.youtube.com/embed/zVibNqf7jeI" frameborder="0" allowfullscreen></iframe>
        </div>
        <h3 class="mt-1 text-center">Paramount Students Mobile Application - Product explanation</h3>
      </div>
    </div>
  </div>
</div>


    <!-- Technical Service Center Section -->
    <div class="tech-form-container">
      <h3 class="text-center">
        <span lang="en" class="notranslate" translate="no">Paramount Students</span> Technical Service Center
      </h3>
      <p class="text-center pt-4">
        You can reach us anytime via
        <a href="mailto:support@paramountstudents.com" lang="en" class="notranslate" translate="no">
          support@paramountstudents.com
        </a>
      </p>

      <!-- Ticket Tabs -->
      <div class="ps-5 border-bottom w-100 d-flex align-items-center">
        <div @click="currentTab = 1" :class="{ 'tabs p-3': true, activeTab: currentTab === 1 }">
          Create Ticket
        </div>
        <div @click="currentTab = 2" :class="{ 'tabs p-3': true, activeTab: currentTab === 2 }">
          Opened Tickets
        </div>
        <div @click="currentTab = 3" :class="{ 'tabs p-3': true, activeTab: currentTab === 3 }">
          Closed Tickets
        </div>
      </div>

      <!-- Create Ticket -->
      <div v-show="currentTab === 1" style="height: 500px">
        <form @submit.prevent="submitForm()" class="tech-service-form">
          <div class="d-flex flex-column">
            <label for="subject"><span class="label-txt">Subject</span></label>
            <input type="text" v-model="form.subject" name="subject" id="subject"
              placeholder="Please write your subject of issue" />
          </div>
          <div class="d-flex flex-column">
            <label for="description"><span class="label-txt">Category</span></label>
            <select v-model="form.ticket_category_id" class="form-select form-select-lg">
              <option disabled>Open this select menu</option>
              <option v-for="option in options" :value="option.id" :key="option.id">
                {{ option.name }}
              </option>
            </select>
          </div>

          <div class="d-flex flex-column">
            <label for="description"><span class="label-txt">Description</span></label>
            <textarea v-model="form.description" name="description" id="description"
              placeholder="Please write your detailed issue description"></textarea>
          </div>
          <button type="submit" class="w-75 mx-auto mt-3">
            Submit
          </button>
        </form>
      </div>

      <!-- Opened Tickets -->
      <div v-show="currentTab === 2">
        <div v-if="tickets.length === 0" class="text-center pt-5">No Opened Tickets</div>
        <div v-for="ticket in tickets" :key="ticket.id" class="ticket-item mt-3">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="ticket-subject">{{ ticket.subject }}</h4>
              <p class="ticket-status">Status: {{ ticket.status }}</p>
            </div>
            <div>
              <button @click="viewTicket(ticket.id)" class="btn btn-primary">View</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Closed Tickets -->
      <div v-show="currentTab === 3">
        <div v-if="closedTickets.length === 0" class="text-center pt-5">No Closed Tickets</div>
        <div v-for="ticket in closedTickets" :key="ticket.id" class="ticket-item mt-3">
          <div class="d-flex justify-content-between">
            <div>
              <h4 class="ticket-subject">{{ ticket.subject }}</h4>
              <p class="ticket-status">Status: {{ ticket.status }}</p>
            </div>
            <div>
              <button @click="viewTicket(ticket.id)" class="btn btn-primary">View</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentTab: 1, // Default to "Create Ticket" tab
      form: {
        subject: '',
        ticket_category_id: '',
        description: '',
      },
      options: [], // Fetch these options from your API or data source
      tickets: [], // Open tickets
      closedTickets: [] // Closed tickets
    };
  },
  methods: {
    async submitForm() {
      // Submit form logic here
       this.$axios.post('/api/tickets', this.form);
    },
    async fetchTickets() {
      // Fetch tickets logic here
       this.tickets = await this.$axios.get('/api/tickets/open');
    },
    async fetchClosedTickets() {
      // Fetch closed tickets logic here
       this.closedTickets = await this.$axios.get('/api/tickets/closed');
    },
    viewTicket(ticketId) {
      // View ticket logic here
       this.$router.push(`/tickets/${ticketId}`);
    }

  },
  mounted() {
    // Fetch initial data here
    this.fetchTickets();
    this.fetchClosedTickets();
  }
};
</script>

<style scoped>
.help-videos {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap; /* This allows wrapping on smaller screens */
}

.video-card {
  width: 250px;
}

.video-card h3 {
  font-size: 1rem;
  text-align: center;
}
.faq-container {
  width: 80%;
  margin: 0 auto;
}

.help-videos-container {
  width: 100%;
}

.help-video {
  position: relative;
}

.videoContainer {
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
}

.tech-form-container {
  width: 90%;
  margin: 0 auto;
}

.tabs {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.activeTab {
  border-bottom: 2px solid #007bff;
}

.ticket-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.ticket-subject {
  font-weight: bold;
}

.ticket-status {
  color: #888;
}

.tech-service-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.tech-service-form label {
  font-weight: bold;
}

.tech-service-form input,
.tech-service-form select,
.tech-service-form textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.tech-service-form button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tech-service-form button:hover {
  background-color: #0056b3;
}
</style>
